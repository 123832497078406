/* /src/style/Recharge.css */
.recharge-container {
    min-height: 100vh;
    background: #f8f9fa;
    padding-top: 5rem;
    margin-top: 5rem;
  }
  
  .card {
    border-radius: 20px;
    background: linear-gradient(135deg, #fff, #f8f9fa);
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .card-header {
    text-align: center;
    padding: 1.5rem;
    background: linear-gradient(90deg, #ff6b6b, #ff4d4d);
    border-bottom: none;
  }
  
  .card-header h2 {
    margin: 0;
    color: #fff;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
  }
  
  .card-body {
    padding: 1.5rem;
  }
  
  .form-label {
    font-weight: bold;
    color: #2d3436;
    font-family: 'Poppins', sans-serif;
  }
  
  .form-control {
    padding: 12px;
    border: 1px solid #ff6b6b;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
    border-radius: 25px;
  }
  
  .btn-qr {
    width: 100%;
    border-radius: 25px;
    padding: 10px;
    background: linear-gradient(90deg, #ff6b6b, #ff4d4d);
    border: none;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease;
  }
  
  .btn-bank {
    width: 100%;
    border-radius: 25px;
    padding: 10px;
    background: linear-gradient(90deg, #28a745, #218838);
    border: none;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    transition: all 0.3s ease;
  }
  
  .btn-card {
    width: 100%;
    border-radius: 25px;
    padding: 10px;
    background: #6c757d;
    border: none;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    opacity: 0.7;
    cursor: not-allowed;
    transition: all 0.3s ease;
  }
  
  .alert-danger {
    margin-top: 1rem;
    border-radius: 25px;
    text-align: center;
    background: #dc3545;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .qr-image {
    border-radius: 15px;
    border: 3px solid #ff6b6b;
    padding: 10px;
    background: #fff;
    transition: transform 0.3s ease;
    max-width: 100%;
    height: auto;
  }
  
  .qr-image:hover {
    transform: scale(1.05);
  }
  
  .bank-info {
    padding: 1rem;
    border-radius: 10px;
    background: #e9ecef;
    border: 1px solid #28a745;
    font-family: 'Poppins', sans-serif;
  }
  
  .bank-qr-image {
    border-radius: 10px;
    border: 2px solid #28a745;
    padding: 5px;
    background: #fff;
    transition: transform 0.3s ease;
    max-width: 200px;
    height: auto;
  }
  
  .bank-qr-image:hover {
    transform: scale(1.05);
  }
  
  .payment-link {
    color: #ff6b6b;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .payment-link:hover {
    color: #ff4d4d;
  }