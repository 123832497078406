/* Header Container */
.header-custom {
  background: #ff6b6b;
  padding: 12px 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
.header-custom:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Logo */
.navbar-brand img {
  max-height: 40px;
  width: auto;
}
.logo-custom {
  transition: transform 0.3s ease;
}
.logo-custom:hover {
  transform: scale(1.1);
}

/* Search Bar */
.search-form {
  max-width: 600px;
  width: 100%;
  flex-grow: 1;
}
.search-wrapper {
  position: relative;
  width: 100%;
  transition: all 0.3s ease;
}
.search-wrapper:hover {
  transform: scale(1.02);
}
.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
  transition: transform 0.3s ease;
}
.search-wrapper:hover .search-icon {
  transform: translateY(-50%) scale(1.2);
}
.search-input {
  padding: 8px 16px 8px 40px !important;
  border-radius: 25px !important;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
  transition: all 0.3s ease;
}
.search-input:focus {
  box-shadow: 0 0 0 3px rgba(255, 140, 0, 0.2);
  border-color: #ff8c00;
}

/* Cart */
.desktop-cart {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
}
.nav-label,
.me-1 {
  color: #fff !important;
}
.desktop-cart .badge,
.cart-icon .badge {
  font-size: 0.6rem !important;
  padding: 2px 5px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  background-color: #ff4d4d !important;
  color: #fff !important;
  border-radius: 50% !important;
  border: 1px solid #fff !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
  min-width: 16px !important;
  height: 16px !important;
  text-align: center !important;
  animation: badgePulse 1.5s ease-in-out infinite !important;
}
.desktop-cart .badge {
  top: 0px !important;
  right: 10px !important;
}
.cart-icon .badge {
  top: 5% !important;
  left: 60% !important;
  transform: translate(-50%, -50%) !important;
}
@keyframes badgePulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* News Button */
.news-btn {
  background: linear-gradient(45deg, #ff6b6b, #ff8c00);
  padding: 6px 12px;
  border-radius: 20px;
  transition: all 0.3s ease;
}
.news-btn:hover {
  background: linear-gradient(45deg, #ff8c00, #ff6b6b);
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* User Button */
.user-btn {
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  border-radius: 20px;
  transition: all 0.3s ease;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.user-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}
.user-btn:hover .dropdown-menu-custom {
  display: block;
}
.user-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  object-fit: cover;
  border: 1px solid #fff;
}
.user-name {
  font-size: 0.9rem;
}

/* Dropdown Menu */
.dropdown-menu-custom {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  min-width: 200px;
  z-index: 1000;
}
.dropdown-item-custom {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: #2d3436;
  text-decoration: none;
  transition: all 0.3s ease;
}
.dropdown-item-custom:hover {
  background: #ff6b6b;
  color: #fff;
}
.points-display {
  padding: 8px 12px;
  color: #2d3436;
  background: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
}

/* Login Button */
.login-btn {
  background: #ff6b6b;
  padding: 6px 12px;
  border-radius: 20px;
  transition: all 0.3s ease;
  color: #fff;
  border: 2px solid #fff;
}
.login-btn:hover {
  background: #e65c5c;
  transform: translateY(-2px);
  color: #fff;
  border: 2px solid #fff;
}

/* Mobile Styles */
@media (max-width: 991px) {
  .desktop-header {
    display: none !important;
  }
  .search-form {
    max-width: 100%;
    margin-top: 0;
  }
  .search-input {
    font-size: 0.85rem;
    padding: 6px 12px 6px 36px !important;
    border-radius: 30px !important;
    background: rgba(255, 255, 255, 0.9);
  }
  .search-icon {
    font-size: 0.9rem;
    left: 10px;
  }
  .mobile-menu {
    background: #ff6b6b;
    border-radius: 0 0 12px 12px;
    padding: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  .mobile-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 0;
  }
  .mobile-nav-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 12px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .mobile-nav-group .nav-link,
  .mobile-nav-group .mobile-nav-item {
    width: 100%;
    padding: 8px 0;
    margin: 0;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #fff;
    transition: background-color 0.3s ease; /* Thêm hiệu ứng hover */
  }
  .mobile-nav-group .nav-link:hover,
  .mobile-nav-group .mobile-nav-item:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Hiệu ứng hover cho danh mục */
  }
  .mobile-nav-group .nav-link i,
  .mobile-nav-group .mobile-nav-item i {
    margin-right: 5px;
    font-size: 1.2rem;
  }
  .mobile-nav-group button.nav-link {
    background: none;
    border: none;
    color: #fff;
    padding: 8px 0;
    width: 100%;
    text-align: left;
    transition: background-color 0.3s ease;
  }
  .mobile-nav-group button.nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .mobile-menu-content {
    width: 100%;
    background: linear-gradient(135deg, #f9f9f9, #ffffff);
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    padding: 10px 0;
    border: 1px solid #e0e0e0;
    animation: slideDown 0.3s ease-in-out;
  }
  .mobile-menu-item {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: 500;
    color: #2d3436;
    transition: all 0.3s ease;
    border-radius: 8px;
    margin: 0 5px;
    text-align: left;
    text-decoration: none;
    position: relative;
  }
  .mobile-menu-item:hover {
    background: #ff6b6b;
    color: #fff;
    transform: translateX(5px);
  }
  .mobile-menu-item i {
    font-size: 1.1rem;
    margin-right: 10px;
    color: inherit;
  }
  .mobile-menu-item:hover i {
    color: #fff;
  }
  .mobile-menu-item::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 8px;
    left: 30px;
    background-color: #ff6b6b;
    transition: width 0.3s ease;
  }
  .mobile-menu-item:hover::after {
    width: calc(100% - 60px);
  }
  .user-avatar {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  .container {
    width: 100% !important;
    max-width: none !important;
    padding: 10px !important;
  }
  .header-custom .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
  }
  .cart-icon {
    padding: 0;
    width: auto;
    z-index: 1000;
    position: relative;
  }
  .cart-icon i {
    font-size: 1.5rem !important;
  }
  .custom-toggler {
    order: 0;
    padding: 0;
    border: none;
    background: none;
  }
  .custom-toggler:focus {
    outline: none;
    box-shadow: none;
  }
  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 10px;
    background: #ff6b6b;
  }
  .mobile-header .col-2 {
    flex: 0 0 16.66%;
    max-width: 16.66%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-header .col-8 {
    flex: 0 0 66.66%;
    max-width: 66.66%;
    padding: 0 5px;
  }
  .mobile-header-icon {
    font-size: 1.5rem;
    color: white;
    transition: transform 0.3s ease;
  }
  .mobile-header-icon:hover {
    transform: scale(1.2);
  }
}

/* Animation */
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

/* Desktop-only */
@media (min-width: 992px) {
  .mobile-menu,
  .mobile-header {
    display: none !important;
  }
}