.order-history-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .order-history-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  }
  
  .order-item {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 15px;
    background: #f8f9fa;
  }
  
  .order-item img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .order-item-details {
    flex-grow: 1;
  }
  
  .status-tag {
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 0.85rem;
    font-weight: 500;
  }
  
  .status-pending {
    color: #007bff;
    border: 1px solid #007bff;
  }
  
  .status-success {
    color: #28a745;
    border: 1px solid #28a745;
  }
  
  .status-failed {
    color: #dc3545;
    border: 1px solid #dc3545;
  }