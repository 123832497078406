/* Import font Poppins từ Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/* Reset và thiết lập font mặc định */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;
}

/* Nút Zalo */
.zalo-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  z-index: 1000;
  animation: zaloExplosion 1.8s infinite ease-in-out;
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: drop-shadow(0 0 10px rgba(0, 255, 127, 0.7));
}

.zalo-button:hover {
  transform: scale(1.2) rotate(10deg);
  filter: drop-shadow(0 0 15px rgba(0, 255, 127, 1));
}

.zalo-button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90px;
  height: 90px;
  background: radial-gradient(circle, rgba(0, 255, 127, 0.5), transparent);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: zaloWave1 2s infinite ease-out;
  z-index: -1;
}

.zalo-button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  background: radial-gradient(circle, rgba(255, 107, 107, 0.4), transparent);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: zaloWave2 2s infinite ease-out 0.3s;
  z-index: -1;
}

@keyframes zaloExplosion {
  0% { transform: scale(1) rotate(0deg); filter: drop-shadow(0 0 10px rgba(0, 89, 255, 0.7)); }
  20% { transform: scale(1.15) rotate(15deg); filter: drop-shadow(0 0 15px rgb(4, 238, 255)); }
  40% { transform: scale(1.1) rotate(-15deg); filter: drop-shadow(0 0 20px rgba(207, 252, 5, 0.9)); }
  60% { transform: scale(1.2) rotate(10deg); filter: drop-shadow(0 0 15px rgb(255, 209, 4)); }
  80% { transform: scale(1.1) rotate(-10deg); filter: drop-shadow(0 0 20px rgba(2, 87, 216, 0.9)); }
  100% { transform: scale(1) rotate(0deg); filter: drop-shadow(0 0 10px rgba(216, 253, 5, 0.7)); }
}

@keyframes zaloWave1 {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 0.8; }
  50% { opacity: 0.5; }
  100% { transform: translate(-50%, -50%) scale(1.8); opacity: 0; }
}

@keyframes zaloWave2 {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 0.6; }
  50% { opacity: 0.4; }
  100% { transform: translate(-50%, -50%) scale(2); opacity: 0; }
}