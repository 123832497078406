/* /src/style/RechargeHistory.css */
.table {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .table thead.table-primary {
    background-color: #007bff;
    color: white;
  }
  
  .table tbody tr {
    transition: all 0.3s ease;
  }
  
  .table tbody tr:hover {
    background-color: #f8f9fa;
  }