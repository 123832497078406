.account-management-container {
    background: #f8f9fa;
  }
  
  .account-menu {
    background: #ff6b6b;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .account-menu-item {
    color: #fff;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .account-menu-item.Mui-selected {
    background: #fff !important;
    color: #ff6b6b !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .account-menu-item:hover {
    background: rgba(255, 255, 255, 0.2) !important;
  }
  
  .account-content {
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .account-content:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  }
  
  .account-avatar {
    border: 2px solid #ff6b6b;
    transition: transform 0.3s ease;
  }
  
  .account-avatar:hover {
    transform: scale(1.05);
  }
  
  .account-upload-btn {
    background: #ff6b6b;
    color: #fff;
    border-radius: 8px;
    padding: 8px 16px;
    transition: all 0.3s ease;
  }
  
  .account-upload-btn:hover {
    background: #ff4d4d;
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .account-info {
    font-size: 1rem;
    color: #2d3436;
    margin-bottom: 10px;
  }
  
  .account-info strong {
    color: #ff6b6b;
  }
  
  .account-divider {
    background: #ff6b6b;
    height: 2px;
    margin: 20px 0;
  }
  
  .account-update-btn {
    background: #ff6b6b;
    color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    transition: all 0.3s ease;
  }
  
  .account-update-btn:hover {
    background: #ff4d4d;
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 767px) {
    .account-menu {
      margin-bottom: 20px;
    }
  }